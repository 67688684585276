<!--
 * @Description: 每日营业统计 折线图
 * @Author: 琢磨先生
 * @Date: 2023-01-16 14:13:06
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-10 09:17:01
-->
<template>
  <div class="revenue_line_chart_box" v-loading="loading">
    <div class="radio_wrp">
      <el-radio-group v-model="query.type" @change="typeChange">
        <el-radio-button :label="1">营业总额</el-radio-button>
        <el-radio-button :label="2">房费</el-radio-button>
        <el-radio-button :label="3">违约金</el-radio-button>
        <el-radio-button :label="4">消费</el-radio-button>
      </el-radio-group>
    </div>
    <div class="chart">
      <v-chart :option="option" autoresize />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      //查询参数
      query: {
        type: 1,
      },
      //图表参数
      option: {},
    };
  },

  props: ["arg"],
  watch: {
    arg: {
      handler() {
        if (this.arg) {
          this.query = Object.assign(this.query, this.arg);
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 选择更改
     */
    typeChange() {
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("seller/v1/analysis/gather/lineChart", this.query)
        .then((res) => {
          if (res.code == 0) {
            // console.log(res);
            var name =
              this.query.type == 1
                ? "营业总额"
                : this.query.type == 2
                ? "房费"
                : this.query.type == 3
                ? "违约金"
                : this.query.type == 4
                ? "消费"
                : "";
            this.option = {
              tooltip: {
                trigger: "axis",
                formatter(values) {
                  var o = values[0];
                  return `${o.axisValue}<br />${name}￥${o.value}`;
                },
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: res.data.map((x) => x.label),
              },
              yAxis: {
                type: "value",
              },
              series: [
                {
                  data: res.data.map((x) => x.value),
                  type: "line",
                  legendHoverLink: true,
                },
              ],
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style  >
.revenue_line_chart_box {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.revenue_line_chart_box .radio_wrp {
  padding: 20px;
}
.revenue_line_chart_box
  .el-radio-button__original-radio:checked
  + .el-radio-button__inner {
  color: var(--el-color-primary);
  background-color: white;
}

.revenue_line_chart_box .chart {
  height: 1px;
  flex-grow: 1;
}
</style>